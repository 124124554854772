import { Customer } from './customer';

export function getCustomerFullName(customer: { firstName: string | null; middleName: string | null; lastName: string | null }): string {
  return [customer.firstName, customer.middleName, customer.lastName].filter(n => !!n).join(' ');
}

export function getCustomerPrimaryPhone(phoneNumbers: Customer['phoneNumbers']): string | null {
  if (phoneNumbers.length === 0)
    return null;

  const preferredPhone = phoneNumbers.find(p => p.preferred);

  if (preferredPhone)
    return preferredPhone.number;

  return phoneNumbers[0].number;
}

export function concatCustomerPhones(phoneNumbers: Customer['phoneNumbers'], separator: string): string | null {
  if (phoneNumbers.length === 0)
    return null;

  return [...phoneNumbers]
    .sort((l, r) => {
      if (l.preferred)
        return -1;

      if (r.preferred)
        return 1;

      return 0;
    })
    .map(p => p.number)
    .join(separator);
}
